import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogContent, MatDialogConfig } from '@angular/material';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public mainTitle = 'Stawika Main System';

    constructor(private translate: TranslateService) {
        translate.setDefaultLang('en'); 
    }

    ngOnInit() {
    }

}
